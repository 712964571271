import React, { FC } from 'react';
import clsx from 'clsx';
import { Row, Column } from '../../atoms/grid';
import { Image } from '../../atoms/image/image';
import { ImageType } from '../../atoms/image/types';
import {
  GridOrientation,
  TTextColor,
  ImageProps,
  TTextVariants
} from '../../types';
import styles from './half-and-half-numbered-list.module.css';
import {
  NumberedList,
  NumberedListProps
} from '../../molecules/numbered-list/numbered-list';
import { useSanityImage } from '../../../hooks/use-sanity-image';

export type ContentPosition = 'center' | 'top';
export interface HalfAndHalfProps extends NumberedListProps {
  imgBgColor?: TTextColor;
  bgColor?: TTextColor;
  image?: ImageProps;
  imagePosition?: 'left' | 'right';
  fullImage?: boolean;
  mobileHidden?: boolean;
  reversed?: GridOrientation;
  variant?: TTextVariants;
  children?: any;
}

export const HalfAndHalfNumberedList: FC<HalfAndHalfProps> = ({
  children,
  image,
  imagePosition,
  fullImage,
  imgBgColor = '',
  bgColor = '',
  mobileHidden = false,
  reversed,
  items,
  variant = 'p2a'
}) => {
  const isReversed =
    reversed !== undefined ? reversed : imagePosition === 'right';
  const halfAndHalfImage = useSanityImage(image);
  const cols = [24, 12];

  const listVariant = variant ?? 'p2a';

  return (
    <Row
      cols={cols}
      gridLength={24}
      reversed={isReversed}
      className={clsx(styles.container, styles[imagePosition], styles.round)}
    >
      <Column
        className={clsx(mobileHidden && styles.mobileHidden, {
          'pt-xl pb-xl': imgBgColor,
          [`bg-${imgBgColor || bgColor}`]: imgBgColor || bgColor
        })}
      >
        <div
          className={clsx(
            {
              'w-full h-full': fullImage,
              'self-align w-pct-17/20': !fullImage
            },
            'pb-sm',
            styles.imageWrapper
          )}
        >
          <Image
            {...halfAndHalfImage}
            type={[
              ImageType.SQUARE,
              halfAndHalfImage?.type ?? ImageType.SQUARE
            ]}
          />
        </div>
      </Column>
      <Column
        className={clsx({
          [`bg-${bgColor}`]: bgColor,
          'pt-sm pb-sm': imgBgColor
        })}
      >
        <div className={clsx(styles.secondColumn, styles.content)}>
          <div className="mb-sm mbs-new">{children}</div>
          <NumberedList items={items} variant={listVariant} />
        </div>
      </Column>
    </Row>
  );
};
