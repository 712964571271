import React from 'react';
import { StructuredList, Text, TextLockup } from '../..';
import { TTextVariants } from 'src/redesign/types';

export interface NumberedListProps {
  items: string[];
  variant?: TTextVariants;
}

export const NumberedList = ({ items, variant = 'p2a' }: NumberedListProps) => {
  return (
    <div className="numbered-list">
      <StructuredList>
        {items.map((item, index) => {
          const num = (index + 1).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          });

          const id = `${index}-${item.split(' ').join('')}`;
          return (
            <div className="flex flex-row w-full pb-xxs pt-xxs" key={id}>
              <Text variant={variant} color="sage" className="mr-md">
                {num}
              </Text>
              <TextLockup alignment="left" fullWidth={true}>
                <Text
                  variant={variant}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              </TextLockup>
            </div>
          );
        })}
      </StructuredList>
    </div>
  );
};
