import React, { FC, useMemo } from 'react';
import clsx from 'clsx';
import { DropdownSelection, Option, Text } from './../../';
import {
  useSelectOptions,
  optionsToString
} from '../../../hooks/use-select-options';
import { useOnToggleScrolling } from '../../../hooks/use-on-toggle-scrolling';
import useDeviceDetect from '../../../hooks/use-is-device-detect';
import styles from './doctor-list-filters.module.css';
import { TFilterFunction } from '../../../hooks/use-providers';

type TBaseOption = { label: string; value: string };
interface TRole extends TBaseOption {}
interface TLocation extends TBaseOption {
  online?: boolean;
}

export type DoctorListFiltersProps = {
  roles: TRole[];
  locations: TLocation[];
  onFilterChange?: TFilterFunction;
  onToggleFilter?: (value: boolean) => void;
};

const allRoleOption = {
  label: 'Medical Providers & Health Coaches',
  value: ''
};
const allLocationOption = { label: 'All Locations', value: '' };

export const DoctorListFilters: FC<DoctorListFiltersProps> = props => {
  const { roles = [], locations = [], onFilterChange, onToggleFilter } = props;
  const { isMobile } = useDeviceDetect();
  const [isView, onToggleIsView] = useOnToggleScrolling(false);
  const [
    viewOptions,
    selectedViewOptions,
    onSelectViewOption
  ] = useSelectOptions<TRole>([allRoleOption, ...roles], [allRoleOption], true);
  const [isLocations, onToggleIsLocations] = useOnToggleScrolling(false);
  const [
    locationOptions,
    selectedLocationOptions,
    onSelectLocationOption
  ] = useSelectOptions<TLocation>(
    [allLocationOption, ...locations],
    [allLocationOption],
    true
  );

  const handleFilterLocation = (option: TLocation) => {
    onSelectLocationOption(option);
    onFilterChange({ filter: 'LOCATION', value: option.value });
  };
  const handleFilterProviderType = (option: TRole) => {
    onSelectViewOption(option);
    onFilterChange({ filter: 'PROVIDER_TYPE', value: option.value });
  };

  const handleOnToggleFilter = (type: 'type' | 'location') => {
    if (type === 'type') {
      if (onToggleFilter) onToggleFilter(!isView);
      onToggleIsView();
    } else {
      if (onToggleFilter) onToggleFilter(!isLocations);
      onToggleIsLocations();
    }
  };

  const { locationsOnSite, locationsOnline } = useMemo(
    () =>
      locationOptions?.reduce(
        (acc, o: TLocation) => {
          if (o.online) {
            return {
              ...acc,
              locationsOnline: [...acc.locationsOnline, o]
            };
          }
          if (o.label !== 'All Locations') {
            return {
              ...acc,
              locationsOnSite: [...acc.locationsOnSite, o]
            };
          }
          return acc;
        },
        { locationsOnSite: [], locationsOnline: [] }
      ),
    [locationOptions]
  );

  if (isMobile) {
    return (
      <DropdownSelection
        isOpen={isView}
        onToggleOpen={() => handleOnToggleFilter('type')}
        heading="Filter View"
        className="mt-sm mb-lg"
        ctaTrackingLabel="Filter View button on doctor list"
      >
        <div className="text-dark-green">
          <div className={styles.borderBottom}>
            <Text variant="p1a">View</Text>
          </div>
          {viewOptions?.map((o, i: number) => (
            <Option
              option={o}
              key={`option-${o.label}-${i}`}
              onSelectOption={() => handleFilterProviderType(o)}
            />
          ))}
          <div className={clsx(styles.borderBottom, 'mt-sm')}>
            <Text variant="p1a">Locations</Text>
          </div>
          <Option
            option={locationOptions[0]}
            onSelectOption={() => handleFilterLocation(locationOptions[0])}
          />
          <div className="mt-sm pb-xtiny">
            <Text variant="u3a">ONSITE & ONLINE</Text>
          </div>
          {locationsOnSite.map((o, i: number) => (
            <Option
              option={o}
              key={`option-${o.label}-${i}`}
              onSelectOption={() => handleFilterLocation(o)}
            />
          ))}
          <div className="mt-sm pb-xtiny">
            <Text variant="u3a">ONLINE</Text>
          </div>
          {locationsOnline.map((o, i: number) => (
            <Option
              option={o}
              key={`option-${o.label}-${i}`}
              onSelectOption={() => handleFilterLocation(o)}
            />
          ))}
        </div>
      </DropdownSelection>
    );
  }

  return (
    <div className="flex flex-row items-center mt-md">
      <DropdownSelection
        isOpen={isView}
        onToggleOpen={() => handleOnToggleFilter('type')}
        heading={
          selectedViewOptions.length && optionsToString(selectedViewOptions)
        }
        ctaTrackingLabel="Type filter button on doctor list"
      >
        {viewOptions?.map((o, i: number) => (
          <Option
            option={o}
            key={`option-${o.label}-${i}`}
            onSelectOption={() => handleFilterProviderType(o)}
            textVariant="p3a"
          />
        ))}
      </DropdownSelection>
      <Text variant="nav" color="current" className="self-center ml-xxs mr-xxs">
        in
      </Text>
      <DropdownSelection
        isOpen={isLocations}
        onToggleOpen={() => handleOnToggleFilter('location')}
        heading={
          selectedLocationOptions.length &&
          optionsToString(selectedLocationOptions)
        }
        ctaTrackingLabel="Location filter button on doctor list"
      >
        <Option
          option={locationOptions[0]}
          onSelectOption={() => handleFilterLocation(locationOptions[0])}
        />
        <div className="mt-sm pb-xtiny">
          <Text variant="u3a">ONSITE & ONLINE</Text>
        </div>
        {locationsOnSite.map((o, i: number) => (
          <Option
            option={o}
            key={`option-${o.label}-${i}`}
            onSelectOption={() => handleFilterLocation(o)}
          />
        ))}
        <div className="mt-sm pb-xtiny">
          <Text variant="u3a">ONLINE</Text>
        </div>
        {locationsOnline.map((o, i: number) => (
          <Option
            option={o}
            key={`option-${o.label}-${i}`}
            onSelectOption={() => handleFilterLocation(o)}
          />
        ))}
      </DropdownSelection>
    </div>
  );
};
