import React from 'react';
import clsx from 'clsx';
import { useLocation } from '@reach/router';

import styles from './footer-cta.module.css';
import { Text } from '../..';
import { Row, Column } from '../../atoms/grid';
import { Button } from '../../atoms/button';
import JSONContent from '../../content/footer/footer-cta-content.json';
import { MouseEventHandler } from 'react-select/src/types';

// @TODO type this correctly once Sanity is added
export type FooterContentKey =
  | 'chronicSymptoms'
  | 'default'
  | 'burnout'
  | 'findSupport'
  | 'join'
  | 'stateChange'
  | 'insights'
  | 'company'
  | 'b2b';

export interface FooterCtaProps {
  footerContentKey?: FooterContentKey;
  positionStatic?: boolean;
}

export const FooterCta: React.FC<FooterCtaProps> = ({ footerContentKey }) => {
  const content =
    JSONContent[footerContentKey?.toLowerCase()] || JSONContent['default'];
  const firstButton = content.buttons[0] || null;
  const secondButton = content.buttons[1] || null;
  const { pathname } = useLocation();
  const firstTrackingLabel = `${firstButton.label} button in footer of ${footerContentKey} page`;
  const secondTrackingLabel = `${secondButton?.label} button in footer of ${footerContentKey} page`;
  const isInsights = footerContentKey === 'insights';

  const showCta = (ctaData: { destination: string } | null) => {
    const baseUrl = /\//g;
    return (
      ctaData &&
      ctaData.destination.replace(baseUrl, '') !== pathname.replace(baseUrl, '')
    );
  };

  const handleSecondButtonClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (secondButton.label === 'Schedule A Call') {
      window.heap.track('Schedule a call');
      window.ours('track', 'Contact');
    }
  };

  return (
    <Row
      cols={23}
      offset={!isInsights && 1}
      className={clsx('flex-center', styles.footerCtaContainer)}
    >
      <Column cols={isInsights && [23, 15]}>
        <Text variant="h2a" color="white" className={styles.ctaHeader}>
          {content.heading}
        </Text>
        <Text variant="p2a" color="white" className={styles.ctaCopy}>
          {content.copy}
        </Text>
        <div className={clsx('inline-flex', styles.buttonsContainer)}>
          {showCta(firstButton) && (
            <Button
              variant="filled"
              color="white"
              destination={firstButton.destination}
              trackingLabel={firstTrackingLabel}
              className={isInsights && styles.insightButtonWidth}
            >
              {firstButton.label}
            </Button>
          )}
          {showCta(secondButton) && (
            <Button
              className={
                isInsights ? styles.insightButtonWidth : styles.scheduleButton
              }
              variant="outlined"
              color="white"
              destination={secondButton.destination}
              trackingLabel={secondTrackingLabel}
              onClick={handleSecondButtonClick}
            >
              {secondButton.label}
            </Button>
          )}
        </div>
      </Column>
    </Row>
  );
};
